<template>
  <div id="app" class="app-wrapper">

    <editor
      ref="editor"
      :isPreviewBtn="isPreview"
      :value="editAmisSchema"
      theme="antd"
      @onChange="onChange"
      @submit="submitHandle"
      @cancel="cancelHandle"
      @synAutoSaveInfo="synAutoSaveInfoHandle"
    ></editor>
    <!-- antd -->
    <a-modal v-model="selectVisible" title="选择同步菜单内容" @ok="handleOk">
      <a-select
        ref="select"
        v-model="selectMenuId"
        placeholder="请选择同步菜单内容"
        style="width: 100%;"
      >
        <a-select-option :value="item.menuId" v-for="item in selectList" :key="item.menuId">{{ item.name }}</a-select-option>
      </a-select>
    </a-modal>
  </div>
</template>

<script>
import AmisDemo from '@/components/amis-demo/AmisDemo.vue'
import Editor from '@/components/amis/AmisEditor.vue'
import { getMenuContent, updateMenuContent, addMenuLog } from '@/api/menu'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import storage from 'store'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'App',
  components: {
    AmisDemo,
    Editor
  },
  data() {
    return {
      editorProps: {},
      isPreview: true,
      editorJson: '',
      value: null,
      menuId: '',
      selectVisible: false,
      selectList: [],
      selectMenuId: null,
      token: encodeURIComponent('Bearer ' + storage.get(ACCESS_TOKEN)),
      autoSaveApiTime: null // 接口保存计时器
    }
  },
  computed: {
    ...mapGetters(['permissions', 'userInfo'])
  },
  created() {
    this.menuId = this.$route.query.menuId
    console.log('permissions>>>>>', this.permissions)

    this.getMenuContent(this.menuId)
    this.$nextTick(() => {
      setTimeout(() => {
        console.log('editAmisSchema>>>>>11>>>>', this.editAmisSchema)
      }, 2000)
    })

    this.autoSaveApiTimeHandle()

    // this.value = this.editAmisSchema
  },
  beforeDestroy() {
    this.clearIntervalApiTime()
  },
  methods: {

    getEditorJson() {
      // this.editorJson = this.$refs.editor.getSchema()

    },
    getMenuContent(menuId) {
      console.log('userInfo>>>>>', this.userInfo)

      getMenuContent({ menuId }).then(response => {
        // console.log('当前amis菜单内容>>>>', response)
        // console.log('当前amis菜单内容>>>>permissions>>>>', this.permissions)
        const schema = response.data ? JSON.parse(response.data) : {}
        if (!schema['data']) {
          schema['data'] = {
            permissions: this.permissions,
            token: storage.get(ACCESS_TOKEN),
            locationUrl: window.location.origin,
            baseURL: process.env.VUE_APP_BASE_API,
            loginUserId: this.userInfo.userId

          }
        } else {
          schema.data['permissions'] = this.permissions

          schema.data['token'] = storage.get(ACCESS_TOKEN)

          schema.data['locationUrl'] = window.location.origin
          schema.data['baseURL'] = process.env.VUE_APP_BASE_API
          schema.data['loginUserId'] = this.userInfo.userId
        }
        // console.log('页面JSON内容>>>>', schema)
        this.editorJson = schema
        this.submitHandle(true)
        this.$store.commit('SET_EDITAMISSCHEMA', schema)
      })
    },
    onChange(e) {
      // console.log('编辑器内容>>>', e)
      this.editorJson = e
      this.$refs.editor.setSchema({
        ...this.editorJson,
        data: {
          amap_info: '123'
        }
      })
    },
    // 同步自动保存内容
    synAutoSaveInfoHandle(menuId) {
      let oldMenuInfo = localStorage.getItem('iot_amis_menu_info')
      let oldMenuList = localStorage.getItem('iot_amis_menu_list')
      if (oldMenuInfo) {
        oldMenuInfo = JSON.parse(oldMenuInfo)
      }

      if (oldMenuList) {
        oldMenuList = JSON.parse(oldMenuList)
        this.selectList = []
        for (const key in oldMenuList) {
          const selectItem = {
            menuId: key,
            name: oldMenuList[key]
          }
          this.selectList.push(selectItem)
        }
      }
      this.selectMenuId = menuId
      this.selectVisible = true
      console.log('同步自动保存内容>>>>oldMenuInfo>>>>', oldMenuInfo)
      console.log('同步自动保存内容>>>>oldMenuList>>>>', oldMenuList)
    },
    // 同步
    handleOk() {
      this.selectVisible = false
      let oldMenuInfo = localStorage.getItem('iot_amis_menu_info')
      if (oldMenuInfo) {
        oldMenuInfo = JSON.parse(oldMenuInfo) || {}
      }

      this.$store.commit('SET_EDITAMISSCHEMA', oldMenuInfo[this.selectMenuId])
    },
    // 保存
    submitHandle(isLog) {
      if (!this.editorJson) {
        // "data":{
        //   "permissions":this.permissions
        // },

        // if (!this.editAmisSchema['data']) {
        //   this.editAmisSchema['data'] = {
        //     permissions: this.permissions
        //     // token: 'Bearer ' + token
        //   }
        // } else {
        //   if (!this.editAmisSchema.data['permissions']) {
        //     this.editAmisSchema.data['permissions'] = this.permissions
        //   }

        //   // if (!this.editAmisSchema.data['token']) {
        //   //   this.editAmisSchema.data['token'] = 'Bearer ' + token
        //   // }
        // }
        this.editorJson = JSON.parse(JSON.stringify(this.editAmisSchema))
      // console.log('保存>>>editAmisSchema>>>>>>', this.editAmisSchema)
      }

      // console.log('保存>>>', this.editorJson)

      this.$store.commit('SET_SAVEAMISSCHEMA', this.editorJson)
      if (isLog) {
        // 保存菜单日志
        this.addMenuLogMeth(JSON.stringify(this.editorJson))
      } else {
        this.saveMenuContent(JSON.stringify(this.editorJson))
      }

      // location.go(-1)
    },

    // 保存当前菜单内容
    saveMenuContent(pageContent) {
      updateMenuContent({
          'menuId': this.menuId,
          'pageContent': pageContent
      }).then(response => {
        console.log('保存当前菜单内容>>>>', response)
        this.$message.success(
                '保存成功',
                3
              )
        // setTimeout(() => {
        //   this.cancelHandle()
        // }, 2000)
        }
      )
    },
    cancelHandle() {
      // window.history.go(-1)
      window.close()
    },
    /**
     * @desc 自动保存接口
     * @param {  }
     * @version: 1.0.0
     */
    autoSaveApiTimeHandle() {
      this.clearIntervalApiTime()
      this.autoSaveApiTime = setInterval(() => {
        this.submitHandle(true)
      }, 1000 * 60)
    },
    /**
     * @desc 清除计时器
     * @param {  }
     * @version: 1.0.0
     */
    clearIntervalApiTime() {
      if (this.autoSaveApiTime) {
        clearInterval(this.autoSaveApiTime)
        this.autoSaveApiTime = null
      }
    },
    /**
     * @desc 保存日志接口
     * @param {  }
     * @version: 1.0.0
     */
    addMenuLogMeth(pageContent) {
      // console.log('addMenuLogMeth>>>>>', moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))

      addMenuLog({
        'menuid': this.menuId,
        'pageContent': pageContent,
        'remark': moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      }).then(response => {
        // console.log('保存当前菜单内容>>>>', response)
        // this.$message.success(
        //         '保存成功',
        //         3
        //       )
      })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.app-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
